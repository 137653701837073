.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

section {
  padding-block-start: 80px;
  min-height: 100vh;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #6f6f6f;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: .2s;
  -ms-transition-duration: .2s;
  -moz-transition-duration: .2s;
  -webkit-transition-duration: .2s;
  -o-transition-duration: .2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.main-title {
  margin-bottom: 50px;
  text-align: center;
}

.main-title h1 {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 15px;
}

.main-title h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 5px;
}

.main-title p {
  font-size: 18px;
  margin-bottom: 0;
}

.pricing {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  z-index: 9;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.pricing .price-header {
  width: 100%;
  background: #fff;
  padding: 35px 20px;
}

.pricing .price-header .title {
  margin: 0 0 10px;
  font-size: 20px;
  font-weight: 600;
  color: #37404d;
}

.pricing .price-header .price {
  font-size: 35px;
  font-weight: 300;
  margin: 0;
  color: #37404d;
}

.pricing .content {
  padding: 20px 20px 30px;
  background: #f9f9f9;
}

.pricing .content ul {
  margin: 0 0 20px;
}

.pricing .content ul li {
  padding: 8px 0;
  font-size: 16px;
  color: #656565;
}

.pricing.featured .listing-badges {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
}

.pricing.featured .featured {
  float: left;
  transform: rotate(-45deg);
  left: -67px;
  top: 17px;
  position: relative;
  text-align: center;
  width: 200px;
  font-size: 13px;
  margin: 0;
  padding: 7px 10px;
  font-weight: 500;
  color: #fff;
}

.pricing.featured .featured {
  background: #195ebf;
}

.btn-outline {
  border: solid 1px #195ebf;
  color: #195ebf;
}

.btn-active {
  background: #ffffff !important;
  color: #000000 !important;
}

.btn-outline:hover {
  border: solid 1px #195ebf;
  background: #195ebf;
  color: #fff;
}

.button-theme {
  background: #195ebf;
  color: #FFF;
}

.button-theme:hover {
  background: #195ebf;
  color: #fff;
}

.pricing-btn {
  padding: 7px 25px 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}




.portfolio-container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.project-title {
  font-size: 28px;
  color: #0073e6;
  margin-bottom: 10px;
}

.project-overview {
  font-size: 16px;
  margin-bottom: 20px;
}

.project-features,
.project-technologies,
.project-challenges,
.project-outcome {
  margin-bottom: 20px;
}

.project-features h3,
.project-technologies h3,
.project-challenges h3,
.project-outcome h3,
.project-links h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0073e6;
}

.project-features ul {
  list-style-type: disc;
  padding-left: 20px;
}

.project-features li {
  margin-bottom: 10px;
}

.project-links a {
  color: #0073e6;
  text-decoration: none;
  margin-right: 10px;
}

.project-links a:hover {
  text-decoration: underline;
}

.project-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 10px;
}

.portfolio-container {
  max-width: 1000px;
  margin-block: 20px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.page-title {
  font-size: 36px;
  color: #0073e6;
  margin-bottom: 30px;
  text-align: center;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-item {
  display: flex;
  background-color: #fafafa;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.project-image {
  max-width: 150px;
  height: auto;
  border-radius: 8px;
  margin-right: 20px;
}

.project-info {
  flex-grow: 1;
}

.project-name {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.project-link {
  display: inline-block;
  margin-right: 10px;
  color: #0073e6;
  text-decoration: none;
  font-size: 16px;
  border: 1px solid #0073e6;
  padding: 5px 10px;
  border-radius: 5px;
}

.project-link:hover {
  background-color: #0073e6;
  color: #fff;
}